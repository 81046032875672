import { createVuetify } from 'vuetify'
import { UADarkTheme, UALightTheme } from '~~/themes/themes'

export default defineNuxtPlugin((nuxtApp) => {
   const vuetify = createVuetify({
      components: {},
      theme: {
         defaultTheme: 'UALightTheme',
         variations: {
            colors: ['primary', 'secondary', 'brand-primary', 'brand-secondary', 'brand-grey', 'warning'],
            lighten: 3,
            darken: 3,
         },
         themes: {
            UADarkTheme,
            UALightTheme,
         },
      },
      display: {
         mobileBreakpoint: 'md',
         // thresholds: {
         //       xs: 0,
         //       sm: 340,
         //       md: 540,
         //       lg: 800,
         //       xl: 1280,
         // },
      },
      defaults: {
         global: {
            ripple: true,
         },
         VSheet: {
            elevation: 4,
         },
         VDataTable: {
            fixedHeader: true,
            noDataText: 'No Data available',
         },
      },
   })

   nuxtApp.vueApp.use(vuetify)
})
