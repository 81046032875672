<script setup lang="ts">
   import { useAppStore } from '~/stores/app'
   import logo from '~/assets/images/UA_logo.png'
   import crest from '~/assets/images/UA_crest.png'

   defineProps({
      appbar: {
         type: Boolean,
         required: false,
         default: false
      }
   })

   const appStore = useAppStore()
</script>

<template>
   <!-- Appbar -->
   <div v-if="appbar" :class="`logo-appbar ${appStore.isMobile ? 'logo-mini' : ''}`">
      <img :src="appStore.isMobile ? logo : crest" />
   </div>
   <!-- Login -->
   <div v-else class="logo">
      <img :src="crest" />
      </div>
</template>

<style scoped>
   .logo {
      margin: -90px auto 20px;
      text-align: center;
   }

   .logo > img {
      height: 160px;
   }

   .logo-appbar {
      top: 32px;
      padding-right: 16px;
      position: relative;
      text-align: center;
   }

   .logo-appbar > img {
      width: 100px;
   }

   .logo-appbar.logo-mini {
      padding-right: 0;
      height: 100%;
      width: fit-content;
      top: 0;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .logo-appbar.logo-mini > img {
      border-radius: 0;
      width: 40px;
   }
</style>
