import type { ThemeDefinition } from "vuetify"

export const UADarkTheme: ThemeDefinition = {
   dark: true,
   colors: {
      primary: '#004280',
      secondary: '#335c83',
      'brand-primary': '#004280',
      'brand-secondary': '#335c83',
      'brand-accent': '#a7c2db',
      accent: '#a7c2db',
      error: '#B00020',
      'error-primary': '#B00020',
      'error-secondary': '#be1d3b',
      'error-accent': '#f59faf',
      info: '#20e3fd',
      success: '#4CAF50',
      warning: '#E48900',
      'warning-secondary': '#ffd7ac',
      'brand-grey': '#778899',
      'brand-white': '#f8faff',
      'white': '#FFFFFF',
   }
}

export const UALightTheme: ThemeDefinition = {
   dark: false,
   colors: {
      primary: '#004280',
      secondary: '#335c83',
      'brand-primary': '#004280',
      'brand-secondary': '#335c83',
      'brand-accent': '#a7c2db',
      accent: '#a7c2db',
      error: '#B00020',
      'error-primary': '#B00020',
      'error-secondary': '#be1d3b',
      'error-accent': '#f59faf',
      info: '#20e3fd',
      success: '#4CAF50',
      warning: '#E48900',
      'warning-secondary': '#ffd7ac',
      'brand-grey': '#778899',
      'brand-white': '#f8faff',
      'white': '#FFFFFF',
   }
}
