import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_NV6MyzF5c3Kpp6FaRlAPkdpgZeYk8Wg5EbiRCprWD4M from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import html2pdf_client_J_ZPtDusnm_qGEp3IRhfluO8L5y6fmb_K6__WCJj_GM from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/plugins/html2pdf.client.ts";
import piniaPlugin_BXtqjuMDNwp6lqFTWiN9cS5LFPKldZy_8r0S3sCfdnc from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/plugins/piniaPlugin.ts";
import vuetify_WIue46TOniyjkogbUIE0Q_xwWw7mT5k7_LeS4ZtT8SM from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/plugins/vuetify.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_NV6MyzF5c3Kpp6FaRlAPkdpgZeYk8Wg5EbiRCprWD4M,
  html2pdf_client_J_ZPtDusnm_qGEp3IRhfluO8L5y6fmb_K6__WCJj_GM,
  piniaPlugin_BXtqjuMDNwp6lqFTWiN9cS5LFPKldZy_8r0S3sCfdnc,
  vuetify_WIue46TOniyjkogbUIE0Q_xwWw7mT5k7_LeS4ZtT8SM
]