import { getCurrentInstance } from 'vue'

export const useAppStore = defineStore('appStore', () => {
   const siteName = ref<string>('Apprentice Hours Portal')   
   const sidebarOpen = ref<boolean>(window.innerWidth >= 1430)
   const drawer = ref<boolean>(false)
   const drawerRail = ref<boolean>(false)
   const helpDrawer = ref<boolean>(false)
   const settingsDrawer = ref<boolean>(false)
   const notificationsDrawer = ref<boolean>(false)
   const rightDrawer = ref<boolean>(false)
   const profileMenu = ref<boolean>(false)
   const notificationsDrawerOpen = ref<boolean>(false)
   const authenticated = ref<boolean>(false)
   const isLoading = ref<boolean>(false)
   const dialogWidth = ref<string>('400px')
   const pageWidth = ref<number>(1024)
   const isMobile = computed(() => { return getCurrentInstance()?.proxy?.$vuetify.display.mobile })
   const refreshKey = ref<number>(0)
   const refrestData = ref({ path: '', user_id: 0, item_id: 0, item_type_id: 0 })
   
   function $reset(): void {
      drawerRail.value = false
      helpDrawer.value = false
      settingsDrawer.value = false
      notificationsDrawer.value = false
      rightDrawer.value = false
      profileMenu.value = false
      notificationsDrawerOpen.value = false
      refrestData.value = { path: '', user_id: 0, item_id: 0, item_type_id: 0 }
   }

   function toggleDrawer(value: boolean): void {
      if (value) {
         drawer.value = value
      } else {
         drawer.value = !drawer.value
      }
   }

   function toggleRightDrawer(): void {
      rightDrawer.value = !rightDrawer.value
   }

   function toggleRightDrawers(drawer: string): void {
      switch (drawer) {
         case 'help':
            helpDrawer.value = !helpDrawer.value
            settingsDrawer.value = false
            notificationsDrawer.value = false
            break
         case 'settings':
            settingsDrawer.value = !settingsDrawer.value
            notificationsDrawer.value = false
            helpDrawer.value = false
            break
         case 'notifications':
            notificationsDrawer.value = !notificationsDrawer.value
            settingsDrawer.value = false
            helpDrawer.value = false
            break
         default:
            break
      }
   }

   function getRefreshData(): any {
      const temp = refrestData.value
      refrestData.value = { path: '', user_id: 0, item_id: 0, item_type_id: 0 }
      return temp
   }

   function setRefreshData(path: string, user_id: number, item_id: number, item_type_id: number): void {
      refrestData.value = { path: path, user_id: user_id, item_id: item_id, item_type_id: item_type_id }
   }

   return {
      siteName,
      sidebarOpen,
      drawer,
      drawerRail,
      settingsDrawer,
      notificationsDrawer,
      rightDrawer,
      profileMenu,
      notificationsDrawerOpen,
      authenticated,
      isLoading,
      dialogWidth,
      pageWidth,
      isMobile,
      refreshKey,
      getRefreshData,
      setRefreshData,
      $reset, toggleDrawer, toggleRightDrawer, toggleRightDrawers
   }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}