
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexar2au793iyKkwQu9yWdVCAjdUdIQuUxtvnyiMk8dMVoMeta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/crafts-tasks/index.vue?macro=true";
import { default as indexAgIdnUpuHprziIxvOprwGuJ1DG03l9PlYqNWu6KALPAMeta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/evaluations/index.vue?macro=true";
import { default as indexRdH5gBwXgtJTbieXJQvg9HHsUoVKrY6BP5Tl_45Rp8QycMeta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/groups/index.vue?macro=true";
import { default as indexII39UKhRFelXY0DzhQ0ypviZfq948P43u5EeL464Y1oMeta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/hours-logs/index.vue?macro=true";
import { default as indexHdPt6pTT0Q_45RuXYKQcgftyaCHbh0UQ1JO5zcB_CPTWoMeta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/index.vue?macro=true";
import { default as index2tCACMS5b9Z7cngXPPOzJXIwLwXIDOL4BRfWDdXMiE8Meta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/login/index.vue?macro=true";
import { default as index16BEFgqIMICAL90GQuIQqaz3lyVOBLvg_nrliufajWkMeta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/outside_evaluation/index.vue?macro=true";
import { default as index9Jsdm_45wgrhIhmc2EITVF_CCZa0fso5Egvnrv7Qu_45vb4Meta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/reports/index.vue?macro=true";
import { default as index0R1T_45qi2gZGAoEDenXHKBm5aVav1Vm3AFFrjGqDf8acMeta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/reset/index.vue?macro=true";
import { default as indexVjZSlAJ_LagQRtBKRJTQG1kdghnBGEMUQDR_gzjRS7cMeta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/support/index.vue?macro=true";
import { default as indexTnxNKF__xjKuCFxYUU_45anfGQkt9MnoF5TqBA0jzcz0cMeta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/training-center/index.vue?macro=true";
import { default as indexdPY1MFBDsjui8p5TrueUbtnXzIAs6Y2L4D_d1sw5DJEMeta } from "C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/users/index.vue?macro=true";
export default [
  {
    name: "crafts-tasks",
    path: "/crafts-tasks",
    meta: indexar2au793iyKkwQu9yWdVCAjdUdIQuUxtvnyiMk8dMVoMeta || {},
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/crafts-tasks/index.vue")
  },
  {
    name: "evaluations",
    path: "/evaluations",
    meta: indexAgIdnUpuHprziIxvOprwGuJ1DG03l9PlYqNWu6KALPAMeta || {},
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/evaluations/index.vue")
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexRdH5gBwXgtJTbieXJQvg9HHsUoVKrY6BP5Tl_45Rp8QycMeta || {},
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/groups/index.vue")
  },
  {
    name: "hours-logs",
    path: "/hours-logs",
    meta: indexII39UKhRFelXY0DzhQ0ypviZfq948P43u5EeL464Y1oMeta || {},
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/hours-logs/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexHdPt6pTT0Q_45RuXYKQcgftyaCHbh0UQ1JO5zcB_CPTWoMeta || {},
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/login/index.vue")
  },
  {
    name: "outside_evaluation",
    path: "/outside_evaluation",
    meta: index16BEFgqIMICAL90GQuIQqaz3lyVOBLvg_nrliufajWkMeta || {},
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/outside_evaluation/index.vue")
  },
  {
    name: "reports",
    path: "/reports",
    meta: index9Jsdm_45wgrhIhmc2EITVF_CCZa0fso5Egvnrv7Qu_45vb4Meta || {},
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/reports/index.vue")
  },
  {
    name: "reset",
    path: "/reset",
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/reset/index.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: indexVjZSlAJ_LagQRtBKRJTQG1kdghnBGEMUQDR_gzjRS7cMeta || {},
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/support/index.vue")
  },
  {
    name: "training-center",
    path: "/training-center",
    meta: indexTnxNKF__xjKuCFxYUU_45anfGQkt9MnoF5TqBA0jzcz0cMeta || {},
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/training-center/index.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexdPY1MFBDsjui8p5TrueUbtnXzIAs6Y2L4D_d1sw5DJEMeta || {},
    component: () => import("C:/Users/csb/Documents/GitHub/UA_Apprentice_Hours_APP/pages/users/index.vue")
  }
]